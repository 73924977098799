import { wrapCreateBrowserRouterV7 } from "@sentry/react";
import { lazy } from "react";
import {
  ActionFunction,
  LoaderFunction,
  RouterProvider,
  createBrowserRouter,
} from "react-router";

const sentryCreateBrowserRouter =
  wrapCreateBrowserRouterV7(createBrowserRouter);

function errorHandler(): { default: () => JSX.Element | false } {
  window.location.reload();
  return { default: () => false };
}

function convert<
  M extends {
    clientLoader?: LoaderFunction | boolean;
    clientAction?: ActionFunction | boolean;
    default?: React.ComponentType | null;
  },
>(m: M) {
  const { clientLoader, clientAction, default: Component, ...rest } = m;
  return {
    ...rest,
    loader: clientLoader,
    action: clientAction,
    Component,
  };
}
const router = sentryCreateBrowserRouter([
  {
    path: "/",
    lazy: () => import("../Root").catch(errorHandler).then(convert),
    children: [
      {
        path: "login",
        lazy: () => import("../pages/Login").catch(errorHandler).then(convert),
      },
      {
        lazy: () => import("../Feedback").catch(errorHandler).then(convert),
        children: [
          {
            path: "respuestas",
            lazy: () =>
              import("../Feedback/Respuestas")
                .catch(errorHandler)
                .then(convert),
            children: [
              {
                index: true,
                lazy: () =>
                  import("../Feedback/Respuestas/TablaRespuestas")
                    .catch(errorHandler)
                    .then(convert),
                handle: {
                  TopbarComponent: lazy(() =>
                    import("../Feedback/Respuestas/BotonEnviador").catch(
                      errorHandler,
                    ),
                  ),
                },
              },
              {
                path: ":serviceId/:patientId",
                lazy: () =>
                  import("../Feedback/Respuestas/Chat")
                    .catch(errorHandler)
                    .then(convert),
              },
            ],
            handle: {
              TopbarComponent: lazy(() =>
                import("../Feedback/Topbar/ServiceSelector").catch(
                  errorHandler,
                ),
              ),
            },
          },
          {
            path: "alertas",
            lazy: () =>
              import("../Feedback/Alerts").catch(errorHandler).then(convert),
            handle: {
              TopbarComponent: lazy(() =>
                import("../Feedback/Alerts/AlertsTopbar").catch(errorHandler),
              ),
            },
          },
          {
            path: "alertas/:serviceId/:patientId",
            lazy: () =>
              import("../Feedback/Alerts").catch(errorHandler).then(convert),
            handle: {
              TopbarComponent: lazy(() =>
                import("../Feedback/Alerts/AlertsTopbar").catch(errorHandler),
              ),
            },
          },
          {
            path: "busqueda",
            lazy: () =>
              import("../Feedback/Search").catch(errorHandler).then(convert),
            handle: {
              TopbarComponent: lazy(() =>
                import("../Feedback/Search/SearchTopbar").catch(errorHandler),
              ),
            },
          },
          {
            path: "dashboard",
            lazy: () =>
              import("../Feedback/Dashboard").catch(errorHandler).then(convert),
            handle: { TopbarComponent: () => "Dashboard" },
          },
          {
            path: "exportar",
            lazy: () =>
              import("../ExportacionAvanzada")
                .catch(errorHandler)
                .then(convert),
          },
          {
            path: "uso",
            lazy: () =>
              import("../Feedback/Uso").catch(errorHandler).then(convert),
          },
          {
            path: "suspensiones",
            children: [
              {
                index: true,
                lazy: () =>
                  import("../Feedback/Suspensions")
                    .catch(errorHandler)
                    .then(convert),
              },
              {
                path: "nueva",
                lazy: () =>
                  import("../Feedback/Suspensions/NewSuspension")
                    .catch(errorHandler)
                    .then(convert),
              },
            ],
          },
          {
            path: "listas-espera",
            lazy: () =>
              import("../Feedback/Waitlists").catch(errorHandler).then(convert),
            children: [
              {
                index: true,
                lazy: () =>
                  import("../Feedback/Waitlists/Campaigns")
                    .catch(errorHandler)
                    .then(convert),
              },
              {
                path: "campanyas/nueva",
                lazy: () =>
                  import("../Feedback/Waitlists/NewCampaign")
                    .catch(errorHandler)
                    .then(convert),
              },
            ],
            handle: { TopbarComponent: () => "Listas de Espera" },
          },
          {
            path: "tutoriales",
            lazy: () =>
              import("../Feedback/Tutoriales")
                .catch(errorHandler)
                .then(convert),
            children: [
              {
                index: true,
                lazy: () =>
                  import("../Feedback/Tutoriales/VideoPlaceholder")
                    .catch(errorHandler)
                    .then(convert),
              },
              {
                path: ":videoId",
                lazy: () =>
                  import("../Feedback/Tutoriales/VideoContainer")
                    .catch(errorHandler)
                    .then(convert),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    lazy: () => import("../pages/NotFound").catch(errorHandler).then(convert),
  },
  {
    path: "autorizacion-meta-tech-provider",
    lazy: () =>
      import("../pages/MetaTechProvider").catch(errorHandler).then(convert),
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
